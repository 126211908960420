// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use 'sass:map';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.all-component-typographies;
@include mat.core;

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

@import './variables';

// TAILWIND
@import 'tailwindcss/utilities';
@import 'tailwindcss/screens';

// Crea tantas variables css como tonos tenga una paleta. Tambien añade el color de contraste para cada tono
@mixin palettes-to-css($palettes) {
  @each $palette-name, $palette in $palettes {
    @each $key, $value in $palette {
      @if $key != contrast {
        #{"--" + $palette-name + "-" + $key}: #{$value};
        #{"--" + $palette-name + "-" + $key + "-contrast"}: #{map.get(map.get($palette, 'contrast'), $key)};
      }
    }
  }
}

// Crea tantas variables css como claves le lleguen
@mixin text-styles-to-css($styles) {
  @each $key, $value in $styles {
    #{"--" + $key}: #{$value};
  }
}

$front-typography: mat.define-typography-config(
  $font-family: $font-family,
);

$light-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($basic-palette, 700),
    accent: mat.define-palette($basic-palette, 700),
    warn: mat.define-palette($error-palette),
  ),
  typography: $front-typography,
));

$light-palettes: (primary: $basic-palette, grey: $grey-palette, success: $success-palette, warn: $warn-palette, error: $error-palette, yellow: $yellow-palette, indigo: $indigo-palette, pink: $pink-palette);

@include mat.all-component-themes($light-theme);

// Crea tantas clases css como tonos tenga una paleta. No añade el contraste para cada tono
@mixin palettes-to-class($palettes) {
  @each $palette-name, $palette in $palettes {
    @each $key, $value in $palette {
      @if $key != contrast {
        .#{$palette-name + "-" + $key} {
          /* stylelint-disable-next-line */
          color: var(#{"--" + $palette-name + "-" + $key})
        }
      }
    }
  }
}

// Como la paleta clara y oscura tienen los mismos nombres de clases vale con llamarla una vez. Al usar variables css las clases seran las mismas en el modo claro/oscuro
@include palettes-to-class($light-palettes);

.light-theme {
  @include palettes-to-css($light-palettes);
  @include text-styles-to-css((primary-text:$dark-primary-text, secondary-text: $dark-secondary-text, disabled-text: $dark-disabled-text, divider: $dark-divider, focused: $dark-focused));
}

$dark-theme: mat.define-dark-theme((
  color: (
    primary: mat.define-palette($basic-dark-palette, 700),
    accent: mat.define-palette($basic-dark-palette, 700),
    warn: mat.define-palette($error-dark-palette),
  ),
  typography: $front-typography,
));

$dark-palettes: (primary: $basic-dark-palette, grey: $grey-dark-palette, success: $success-dark-palette, warn: $warn-dark-palette, error: $error-dark-palette, yellow: $yellow-dark-palette, indigo: $indigo-dark-palette, pink: $pink-dark-palette);

.dark-theme {
  @include mat.all-component-colors($dark-theme);
  @include palettes-to-css($dark-palettes);
  @include text-styles-to-css((primary-text:$light-primary-text, secondary-text: $light-secondary-text, disabled-text: $light-disabled-text, divider: $light-divider, focused: $light-focused));
}

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: $font-family;
  font-style: normal;
}

.primary-font {
  font-family: $font-family;
}

// Toast styles
@import 'ngx-toastr/toastr';
@import './../../common/styles/fonts'; // Aqui para que exporte los mixins de fonts
@import './../../common/styles/mixins/main';

// Material rewriting
[class*='theme'] {
  @import './../../common/styles/components/background';
  @import './../../common/styles/components/accordion';
  @import './../../common/styles/components/buttons';
  @import './../../common/styles/components/dialogs';
  @import './../../common/styles/components/inputs';
  @import './../../common/styles/components/links';
  @import './../../common/styles/components/navbar';
  @import './../../common/styles/components/table';
  @import './../../common/styles/components/icons';
  @import './../../common/styles/components/others';
  @import './../../common/styles/components/imgs';
  @include mat.radio-density(-2); // Reduce el tamaño de la sombra del radio buton
}

// Esto no usa material por lo que va fuera.
@import './../../common/styles/components/toaster';
@import './../../common/styles/helpers';

// Este componente tiene un elipsis en el label para que si es mas largo que el contenedor salgan puntos suspensivos.
// Por lo visto eso descuadra el tamaño del propio contenedor y es necesario clavarle esto al que hace de columna.
app-autocomplete-select {
  min-width: 0;
}
