.mat-mdc-tab .mdc-tab__text-label,
.mat-mdc-tab-link .mdc-tab__text-label {
  color: var(--primary-700);
  text-transform: capitalize;
}

.mat-mdc-tab.active-link .mdc-tab__text-label,
.mat-mdc-tab-link.active-link .mdc-tab__text-label {
  color: var(--grey-1000);
}

// Para que lo tengan las modales pero no el canvas del record
.border-bottom-nav .active-link {
  border-bottom: 3px solid var(--primary-500);
}

.mdc-dialog__title {
  padding: 0;
}

.navbar__item:hover,
.mat-list-option:hover,
.mat-list-option:focus,
.mat-nav-list .mat-mdc-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: var(--primary-100);
}

.mdc-tab,
.mdc-tab--active {
  &.mdc-tab--active {
    .mdc-tab__content {
      .mdc-tab__text-label {
        color: var(--grey-1000);
      }
    }
  }
}

.mdc-tab {
  margin: 0 12px;
}

// border bottom de las nav-bars seleccionadas de permisos
.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--primary-500);
}

.mat-mdc-tab-link {
  padding: 0 8px;
}
