@import '../mixins/fonts';

th {
  white-space: nowrap;
}

.mat-mdc-table {
  & th {
    @include actions-a3;
    background: var(--grey-100);
    color: var(--grey-700);
    border-bottom: 1px solid var(--grey-300);

    &.center {
      text-align: center;
    }
  }

  & td .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.mat-mdc-header-cell {
  color: var(--grey-700);
}

// estilos rows deshabilitadas en dashboard
.disabled-row {
  & .link {
    color: var(--grey-700);
    cursor: default;
    pointer-events: none;
  }

  & mat-icon {
    color: var(--grey-700);
  }
}

// SOMBRAS CELDAS
.shadow {
  box-shadow: -5px 0 5px -3px rgb(0 0 0 / 5%);
}

.mat-column-emptyStickyShadowColumn,
.shadow-right {
  box-shadow: 5px 0 5px -3px rgb(0 0 0 / 5%);
}

// ANCHOS CELDAS

.cell-xs {
  min-width: 100px;
  max-width: 100px;
}

.cell-s {
  min-width: 150px;
  max-width: 150px;
}

.cell-m {
  min-width: 150px;
  max-width: 200px;
}

.cell-l {
  min-width: 300px;
  max-width: 350px;
}

.cell-xl {
  min-width: 350px;
  max-width: 450px;
}

.icon-cell {
  min-width: 36px;
  max-width: 36px;
}

// BORDE TABLA, poner en el div que envuelve la tabla y en el paginador
.border-tb {
  border: 1px solid var(--grey-300);
  border-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.border-paginator {
  border-left: 1px solid var(--grey-300);
  border-bottom: 1px solid var(--grey-300);
  border-right: 1px solid var(--grey-300);
  border-top: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  &--empty {
    border-radius: 8px;
    border: 1px solid var(--grey-300);
  }
}
