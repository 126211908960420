.main-background {
  background: url('/assets/img/background/image-air.webp') bottom center no-repeat;
  background-size: cover;
  height: 100%;
}

.smartgp-background {
  background: url('/assets/img/background/image-smartgp.webp') center no-repeat, #1D70B7;
  background-size: cover;
  max-width: 100%;
}
