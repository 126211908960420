.mat-mdc-icon-button svg,
.mat-mdc-icon-button img,
.mat-icon {
  width: 17px;
  height: 17px;
}

.mat-icon {
  color: var(--grey-700);
}

.mat-mdc-form-field-icon-prefix > .mat-icon,
.mat-mdc-form-field-icon-suffix > .mat-icon {
  padding: 0;
}

.mat-icon.mat-primary {
  color: var(--primary-700);
}

.mat-icon.mat-warn {
  color: var(--error-500);
}

// iconos home y navbar
.mat-icon-l {
  width: 24px;
  height: 24px;
}

.mat-icon-m {
  width: 16px;
  height: 16px;
}

// cambia el color del icono deshabilitado en botones
button[disabled] .mat-icon {
  color: var(--grey-400);
}

// Tamaño del icono que acompaña inputs
mat-datepicker-toggle .mat-mdc-icon-button.mat-mdc-button-base {
  padding: 0;
  width: 23px;
  height: 23px;
  margin-top: 4px;
}
