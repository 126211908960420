.mat-expansion-panel:not([class*='mat-elevation-z']) {
  box-shadow: none;
}

// elimina el padding lateral
.mat-expansion-panel-header {
  padding: 0 4px !important;
}

// elimina el fondo gris
.mat-expansion-panel-header,
.mat-expansion-panel-header:hover {
  background: transparent !important;
}

.reset .mat-expansion-panel-header {
  padding: 0;
}

.reset .mat-expansion-panel-body {
  padding: 0 !important;
}

.reset .mat-expansion-panel-header.mat-expanded {
  height: initial;
}

.card-primary .mat-expansion-panel {
  background-color: var(--grey-200);
}

.content-bg > .mat-expansion-panel-content {
  background: var(--grey-100);
}
