$dark-primary-text: #100316;
$dark-secondary-text: #69606D;
$dark-disabled-text: #BBB7BD;
$dark-divider: #D2CFD3;
$dark-focused: #433C46;
$light-primary-text: #FFFFFF;
$light-secondary-text: #D2CFD3;
$light-disabled-text: #68606C;
$light-divider: #DAD7DA;
$light-focused: #D1CFD2;

$basic-palette: (
  50: #F6FCFC,
  100: #ECF8F8,
  200: #C8EEEC,
  300: #9EE1DE,
  400: #5DCDC7,
  500: #2CB9B2,
  600: #019D96,
  700: #01837D,
  800: #265D5A,
  900: #004745,
  1000: #003834,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    1000: $light-primary-text,
  )
);

$basic-dark-palette: (
  50: #001A18,
  100: #002423,
  200: #003330,
  300: #014744,
  400: #016561,
  500: #01837D,
  600: #38ADA7,
  700: #5DCDC7,
  800: #86DAD5,
  900: #A5E3E0,
  1000: #D8F3F2,
  contrast: (
    50: $light-primary-text,
    100: $light-primary-text,
    200: $light-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $dark-primary-text,
    1000: $dark-primary-text,
  )
);

$grey-palette: (
  50: #FFFFFF,
  100: #FAFAFA,
  200: #F2F2F2,
  300: #DAD7DA,
  400: #BBB7BD,
  500: #9F9AA2,
  600: #8B858E,
  700: #68606C,
  800: #423946,
  900: #231C26,
  1000: #100316,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    1000: $light-primary-text,
  )
);

$grey-dark-palette: (
  50: #170D1C,
  100: #231B27,
  200: #2B232F,
  300: #342D38,
  400: #433C46,
  500: #57505A,
  600: #6B636F,
  700: #897F8E,
  800: #A8A1AC,
  900: #D2CFD3,
  1000: #FFFFFF,
  contrast: (
    50: $light-primary-text,
    100: $light-primary-text,
    200: $light-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $dark-primary-text,
    1000: $dark-primary-text,
  )
);

$yellow-palette: (
  100: #FFF8DB,
  200: #FFEA8F,
  300: #FFDA34,
  400: #EDC200,
  500: #CFA900,
  600: #AD8D00,
  700: #8E7400,
  800: #715C00,
  900: #504100,
  1000: #322900,
  contrast: (
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    1000: $light-primary-text,
  )
);

$yellow-dark-palette: (
  100: #322900,
  200: #504100,
  300: #715C00,
  400: #8E6B00,
  500: #9C8211,
  600: #BA9C15,
  700: #D5B318,
  800: #E1C652,
  900: #F4E39A,
  1000: #FFF8DB,
  contrast: (
    100: $light-primary-text,
    200: $light-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $dark-primary-text,
    1000: $dark-primary-text,
  )
);

$indigo-palette: (
  100: #E5E7FF,
  200: #C3CAFE,
  300: #A5AEFD,
  400: #7E8BFD,
  500: #5667FC,
  600: #3549FB,
  700: #3109FB,
  800: #2504C8,
  900: #1B0393,
  1000: #120263,
  contrast: (
    100: $light-primary-text,
    200: $light-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $dark-primary-text,
    1000: $dark-primary-text,
  )
);

$indigo-dark-palette: (
  100: #1B1050,
  200: #221179,
  300: #242FA8,
  400: #4424E0,
  500: #4B5AE5,
  600: #6774EB,
  700: #8B95F0,
  800: #A5AEFD,
  900: #C3CAFE,
  1000: #E5E7FF,
  contrast: (
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    1000: $light-primary-text,
  )
);

$pink-palette: (
  100: #FCE9F4,
  200: #F8CDE6,
  300: #F5A8D4,
  400: #FD86CA,
  500: #E84AA4,
  600: #E42692,
  700: #BC1775,
  800: #93125B,
  900: #680D40,
  1000: #4D0A30,
  contrast: (
    100: $light-primary-text,
    200: $light-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $dark-primary-text,
    1000: $dark-primary-text,
  )
);

$pink-dark-palette: (
  100: #44132F,
  200: #5C193F,
  300: #7D2858,
  400: #A72C72,
  500: #C3478E,
  600: #DA58A2,
  700: #DD88B8,
  800: #EFAFD3,
  900: #F0D6E5,
  1000: #FCE9F4,
  contrast: (
    100: $light-primary-text,
    200: $light-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $dark-primary-text,
    1000: $dark-primary-text,
  )
);

$success-palette: (
  100: #E7F9E7,
  300: #B7FAB7,
  500: #7FDC7F,
  700: #3E843E,
  contrast: (
    100: $dark-primary-text,
    300: $dark-primary-text,
    500: $dark-primary-text,
    700: $light-primary-text,
  )
);

$success-dark-palette: (
  100: #233823,
  300: #427B42,
  500: #8FE08F,
  700: #D5F6D5,
  contrast: (
    100: $light-primary-text,
    300: $dark-primary-text,
    500: $dark-primary-text,
    700: $dark-primary-text,
  )
);

$warn-palette: (
  100: #FFF4E5,
  300: #FFD8A8,
  500: #FFB152,
  700: #AA6109,
  contrast: (
    100: $dark-primary-text,
    300: $dark-primary-text,
    500: $dark-primary-text,
    700: $light-primary-text,
  )
);

$warn-dark-palette: (
  100: #442B13,
  300: #895724,
  500: #FFB266,
  700: #FFDEBD,
  contrast: (
    100: $light-primary-text,
    300: $dark-primary-text,
    500: $dark-primary-text,
    700: $dark-primary-text,
  )
);

$error-palette: (
  100: #FEE9E7,
  300: #FC9C9C,
  500: #C8414F,
  700: #960816,
  contrast: (
    100: $dark-primary-text,
    300: $dark-primary-text,
    500: $light-primary-text,
    700: $light-primary-text,
  )
);

$error-dark-palette: (
  100: #3D1418,
  300: #7F2F33,
  500: #E96C67,
  700: #FCCAC5,
  contrast: (
    100: $light-primary-text,
    300: $light-primary-text,
    500: $dark-primary-text,
    700: $dark-primary-text,
  )
);

$font-family: 'Red Hat Display', sans-serif;

// breakpoints
$tablet: 768px;
$desktop: 1024px;
