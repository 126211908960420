@import '../mixins/fonts';

/* stylelint-disable property-no-vendor-prefix */
// Oculta las flechas de los inputs number en Chrome, Safari, Edge, Opera
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// Oculta las flechas de los inputs number en Firefox
input[type='number'] {
  -moz-appearance: textfield;
}

/* stylelint-enable property-no-vendor-prefix */

.mat-mdc-form-field-infix > .mat-mdc-input-element {
  @include body-b2;
}

// Cambia color cursor cuando error en input
.mat-mdc-form-field-invalid .mat-mdc-input-element,
.mat-mdc-form-field.mat-warn .mat-mdc-input-element {
  caret-color: var(--primary-700);
}

// Para pisar el espacio debajo que mete material
.mat-mdc-input-element[type='time']::after {
  white-space: inherit;
}

.form-container__form-field {
  @include label;

  &__label {
    // da altura al label, esté vacío o no
    height: 18px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    // cambio el calor de todas las labels
    color: var(--grey-900);
  }
}

// Estos dos bloques sirven para que los inputs tengan el alto requerido
.mat-mdc-form-field-infix {
  width: auto;
  min-height: 29px;
}

.mat-mdc-form-field-flex > .mat-mdc-form-field-infix {
  padding-top: 0.2em;
  padding-bottom: 0;
}

// separación del input por la izquierda
.mdc-text-field--outlined {
  padding-left: 0.5em !important;
}

.mat-mdc-select-placeholder {
  @include body-b2;
}

.mat-mdc-form-field:not(.mat-form-field-appearance-legacy) .mat-mdc-form-field-prefix .mat-mdc-datepicker-toggle-default-icon,
.mat-mdc-form-field:not(.mat-form-field-appearance-legacy) .mat-mcd-form-field-suffix .mat-mcd-datepicker-toggle-default-icon {
  width: 1.2em;
  height: 1.2em;
  padding: 0;
}

// Tamaño radios
.mdc-radio__background {
  margin-top: 4px;
  margin-right: 4px;
  width: 12px;
  height: 12px;
}

.mdc-radio__inner-circle {
  top: -4px;
  left: -4px;
}

.mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__inner-circle {
  transform: scale(0.3);
}

// Quita uno de los efectos que no hay quien lo centre
.mdc-radio__background::before {
  content: none;
}

// Para pisar que por defecto trae de color de fondo whitesmoke
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent;
}

// Borde General
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--grey-300);
}

// Borde Error
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--error-500);
  border-width: 2px;
}

// Borde Focus
// encadenamos el disabled y el invalid con el :not para que el borde de error prevalezca antes que el de focus verde.
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-width: 2px;
  border-color: var(--primary-700);
}

// Borde Hover
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):not(.mdc-text-field--invalid):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):not(.mdc-text-field--invalid):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):not(.mdc-text-field--invalid):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--grey-600);
  border-width: 2px;
}

// Tamaño de los select
.mat-mdc-select,
.mat-mdc-option {
  font-size: 14px;
}

.mat-mdc-select {
  margin: 0 5px;
}

// Reduce alto del paginador
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 0.2em;
  padding-bottom: 0;
}

.mat-mdc-option {
  min-height: 30px;
}

// Para quitar el padding del buscador del listado de expedientes
.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 3px;
  padding-bottom: 0;
}

// Para quitar el iconon de los input toggle
.mdc-switch__icons {
  display: none;
}

// Para quitar el overlay gris del input al tener hover
.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0;
}

.mat-mdc-form-field-icon-suffix span {
  margin-right: 5px;
}

// Pisa el fondo transparente del input por blanco
.mdc-text-field--outlined .mdc-notched-outline {
  z-index: 0;
  background-color: var(--grey-50);
}

// CHECKBOX EN LISTAS

.mat-mdc-list-option {
  padding-left: 0 !important;
}

// cuando se usa un mat-list-option material le mete un span::before con esta clase que desalinea los checkbox... pues eso.
.no-padding > .mdc-list-item__content {
  display: none;
}

.mdc-list-item__end {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

// sube el icono
.mat-mdc-form-field-icon-prefix,
.mat-mdc-form-field-icon-suffix {
  z-index: 5;
}

.mat-mdc-select-disabled .mat-mdc-select-placeholder {
  color: var(--grey-400);
}

// elimina fondo de la opción seleccionada por defecto en los select para igualarlos
.mat-mdc-option.mat-mdc-option-active {
  background: transparent;
}

.mat-mdc-option:hover {
  background: transparent;
}

// controlan colores de los hover en las opciones de los drops

.mdc-menu-surface.mat-mdc-select-panel {
  background: var(--grey-50);

  // Para pisar el margin top de los select desplegables
  padding: 0;
}

.mdc-menu-surface.mat-mdc-select-panel > * > span:hover {
  background-color: var(--grey-100);
}
