// Shadows

@mixin shadow-standard {
  box-shadow: 0 8px 24px rgba(21 21 22 / 4%) !important;
}

@mixin shadow-dark {
  box-shadow: 0 8px 24px rgb(46 34 51 / 10%);
}

@mixin shadow-modal {
  box-shadow: 0 11px 30px rgba(0 56 52 / 15%);
}

// Breakpoints

@mixin media($width) {
  @media all and (min-width: $width) {
    @content;
  }
}
