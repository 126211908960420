// Estilos barra scroll

div ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--grey-400);
}

div ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: var(--grey-200);
}

div {
  scrollbar-color: var(--grey-400) var(--grey-200);
}

// Toolbar
.mat-drawer,
.mat-toolbar {
  background-color: var(--grey-50);
}

// Fondo
.mat-drawer-container {
  background-color: var(--grey-100);
}

// Paginador
.mat-mdc-paginator {
  background-color: var(--grey-100);
  color: var(--primary-text);

  &-container {
    padding: 0;
  }
}

// Necesario para evitar el que icono de captcha este por debajo del footer
.grecaptcha-badge {
  bottom: 48px !important;
}

// Para evitar que los inputs de contraseña recordad se vean distintos
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--primary-text);
  box-shadow: 0 0 0 1000px transparent inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
}

// Estilos menú desplegable superpuesto

.mat-mdc-menu-panel {
  border-radius: 12px;
}

.mat-mdc-menu-content:not(:empty) {
  border-radius: 12px;
  padding-top: 0;
}

.mat-mdc-menu-item {
  height: 36px;
}

// Para que los select con buscador no tengan ancho fijo
.cdk-overlay-pane:has(> div[role='listbox']) {
  width: auto !important;
}

// Para que la cabecera del mat-tab-group no ocupe el 100%
.max-width-header .mat-mdc-tab-header {
  max-width: 80%;
}

// Para que uno de los divs que crean los mat-tab ocupe el alto completo
.h-full .mat-mdc-tab-body-wrapper {
  height: 100%;
}

.mat-drawer-content {
  overflow: hidden;
}
