.mat-mdc-button-base {
  font-size: 14px;
  line-height: 21px !important; // Important necesario: la clase de mat-stroked lo pisa sin el.
  letter-spacing: 0.04em !important;
  border-radius: 40px !important;
}

.mat-mdc-unelevated-button,
.mat-mdc-outlined-button {
  padding: 8px 20px !important;
}

.mat-mdc-unelevated-button {
  border-radius: 30px !important;
}

.mat-flat-button--black {
  // caso especial, button de 404
  & .mat-mdc-outlined-button.mat-primary {
    color: var(--primary-600-contrast);
  }
}

.mat-mdc-mini-fab,
.mat-mdc-button-base,
.mat-mini-fab.mat-accent {
  background: none;
  box-shadow: none;
}

// Para pisar los efectos del hover y del click hay que pisar el before que crea el propio material en el botón

// Efecto hover
.mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,
.mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.2;
  background-color: var(--primary-500);
}

// aquí le tenemos que vaciar el content para que no persista el color. ojo que el click se le deshabilita de manera inline en el propio botón.

.mat-mdc-button:hover[disabled] .mat-mdc-button-persistent-ripple::before,
.mat-mdc-outlined-button:hover[disabled] .mat-mdc-button-persistent-ripple::before {
  content: none;
}

button[mat-flat-button][disabled] {
  background: var(--grey-600);
  pointer-events: none !important;
}
